import moment from 'moment';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';

export const TIMESTAMP_PARAM_NAME = 'timestamp';

export function tokenIsValid(token) {
  return uuidValidate(token);
}

export function generateToken() {
  return uuidv4();
}

export async function timestampIsValid(req) {
  const appSettings = await req.getAppSettings();
  const timestamp = req.query[TIMESTAMP_PARAM_NAME];

  /*
   * Checks if the timestamp is correctly formatted
   * and within the expected range
   */
  if (!timestamp) {
    return false;
  }
  const timestampMoment = moment(timestamp);
  if (!timestampMoment.isValid()) {
    return false;
  }
  const now = moment();
  const allowedPastMinutes =
    appSettings['TRACKING_TOKEN_URL_TIMESTAMP_ALLOWED_PAST_MINUTES'];
  const allowedFutureMinutes =
    appSettings['TRACKING_TOKEN_URL_TIMESTAMP_ALLOWED_FUTURE_MINUTES'];
  const earliestTime = now.clone().subtract(allowedPastMinutes, 'm');
  const latestTime = now.clone().add(allowedFutureMinutes, 'm');
  return timestampMoment.isBetween(earliestTime, latestTime);
}

export function getFromQuery(req, queryParam) {
  const token = req.query[queryParam];
  if (token && tokenIsValid(token)) {
    return token;
  }
  return null;
}

export function getFromCookies(req, cookieName) {
  const token = req.cookies[cookieName];
  if (token && tokenIsValid(token)) {
    return token;
  }
  return null;
}

/*
 * 1. If token is a query param in the URL, use that
 * 2. If token is set in a cookie, use that
 * 3. Otherwise, create a new token and return it
 * Note: This method does not set the token in a cookie.
 */
export async function getToken(
  req,
  {
    // query param to read the token's value from
    queryParamName = null,
    // name of the cookie to read the value
    cookieName = null,
    // only return token from the url if the timestamp param is valid
    checkQueryTimestamp = false
  }
) {
  if (queryParamName) {
    const queryValue = getFromQuery(req, queryParamName);
    if (queryValue) {
      if (checkQueryTimestamp) {
        if (await timestampIsValid(req)) {
          return queryValue;
        }
        // if timestamp is invalid, we won't return the value from query
        // and instead fall through to cookie check
      } else {
        return queryValue;
      }
    }
  }

  if (cookieName) {
    const cookieValue = getFromCookies(req, cookieName);
    if (cookieValue) {
      return cookieValue;
    }
  }

  return generateToken();
}

export const CONSUMER_TRACKING_TOKEN = {
  queryParamName: 'tt',
  checkQueryTimestamp: true,
  cookieName: 'consumer_tracking_token'
};

export const SEARCH_TOKEN = {
  queryParamName: 'search_token',
  checkQueryTimestamp: false,
  cookieName: null // search token does not get added to nor read from cookies
};

export const USER_TOKEN = {
  queryParamName: 'ut',
  checkQueryTimestamp: true,
  cookieName: 'consumer_user_token'
};

export const SEARCH_SHUFFLE_TOKEN = {
  queryParamName: 'sst',
  checkQueryTimestamp: true,
  cookieName: 'search_shuffle_token'
};
