import 'whatwg-fetch';
import './webpack-public-path';
//  import fetchIntercept from 'fetch-intercept';

import {
  theme as biomeTheme,
  ThemeProvider as BiomeThemeProvider,
  THEME_ID
} from '@biome-design-system/styles';
import { datadogRum } from '@datadog/browser-rum';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/core';
import * as FullStory from '@fullstory/browser';
import { Search } from '@kyruus/search-sdk';
import trackyr from '@kyruus/trackyr';
import loadable, { loadableReady } from '@loadable/component';
import axios from 'axios';
import Cookies from 'js-cookie';
import { func, object } from 'prop-types';
import querystring from 'querystring';
import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Switch, withRouter } from 'react-router-dom';

import kyruusTheme from '@kyruus/ui-theme';
import { shouldRenderDirectBookInDrawer } from 'Common/config';
import { getConfigProperty } from '../../common/config/utils';
import '../stylesheets/providermatch-consumer.scss';
import { setTestConfigs } from './behaviors/configuration';
import { setBcsToken } from './behaviors/tokens';
import { AuthenticatedUserInfoProvider } from './hooks/useAuthenticatedUserInfo';
import {
  CrmConnectorProvider,
  ReferralConnectorProvider
} from './referral-crm-connector';
import configureStore from './store/configure-store';
import { shouldTrackToTealium, trackTealiumEvent } from './tracking/tealium';
import {
  getExternalTrackingParams,
  isGoogleAnalyticsLoaded,
  trackGaEvent
} from './tracking/tracking-utils';
import { CERNER_BCS_TOKEN_QUERY_PARAM } from './utils/cerner';
import State from './utils/state';
import { getLoginRedirectUrl } from './utils/url';

import { ThemeProvider } from 'emotion-theming';
import { productNameSelector } from './behaviors/product-name';
import CustomConfigActiveBanner from './editor/custom-config-active-banner';
import {
  AppointmentCancelRoute,
  DirectBookDrawerRoute,
  DirectBookRoute,
  LocationRoute,
  MapSearchRoute,
  ProfileRoute,
  RedirectRoute,
  SearchMatchRoute
} from './routes';
import LoadingOverlay from './shared/loading';

const APP_ID = 'pmc-app';
const EDITOR_APP_ID = 'pmc-editor-app';

const HomeContainer = loadable(() => import('./containers/home-container'));
const HomeContainerV9 = loadable(() =>
  import('./containers/home-container-v9')
);
const ProfileContainer = loadable(() =>
  import('./containers/profile-container')
);
// Lazily load DBW container with method to preload it when needed
const DBookingContainer = loadable(() =>
  import('./containers/direct-booking-container')
);
const AppointmentCancelContainer = loadable(() =>
  import('./containers/appointment-cancel-container')
);
const RedirectContainer = loadable(() =>
  import('./containers/redirect-container')
);
const LocationContainer = loadable(() =>
  import('./containers/location-container')
);
const MapSearchContainer = loadable(() =>
  import('./containers/map-search-container')
);
const MapSearchContainerV9 = loadable(() =>
  import('./containers/map-search-container-v9')
);
const PmcEditor = lazy(() => import('./editor/editor'));

const ClientApp = withRouter(
  ({
    log,
    config,
    customerCode,
    authenticatedUserInfo,
    location,
    productName
  }) => {
    const interceptRequestSuccess = (config) => {
      config.headers = {
        ...config.headers,
        // value doesn't matter, the header just needs to exist on the request
        'x-csrf-header': customerCode,
        'X-Requested-With': 'XMLHttpRequest'
      };
      return config;
    };
    const interceptError = (error) => {
      return Promise.reject(error);
    };
    // axios interceptors
    axios.interceptors.request.use(interceptRequestSuccess, interceptError);
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          (error.status === 403 || error.response?.status === 403) &&
          (error.data?.loginServiceUrl || error.response?.data?.loginServiceUrl)
        ) {
          const loginServiceUrl =
            error.data?.loginServiceUrl ||
            error.response?.data?.loginServiceUrl;
          const loginRedirectUrl = getLoginRedirectUrl(
            window.location,
            loginServiceUrl
          );
          return window.location.replace(loginRedirectUrl);
        }
        return Promise.reject(error);
      }
    );
    // Disabling this feature for release 9.0.0 (KENG-40098) as it intereferes with a fetch request
    // to youtube in the provider-profile-component package and breaks provider videos on the profile
    // leaving code commented here for the followup fix to use
    //
    // fetch interceptors
    // fetchIntercept.register({
    //   request: function (url, config) {
    //     config = interceptRequestSuccess(config);
    //     return [url, config];
    //   },
    //   requestError: interceptError,
    //   response: async function (response) {
    //     if (response.status === 403) {
    //       const { loginServiceUrl } = await response.json();
    //       const loginRedirectUrl = getLoginRedirectUrl(
    //         window.location,
    //         loginServiceUrl
    //       );
    //       return window.location.replace(loginRedirectUrl);
    //     }
    //     return response;
    //   },
    //   responseError: interceptError
    // });

    const searchSDK = useRef(
      new Search({
        customerCode,
        productName,
        baseURL: '/api/searchservice-v9'
      })
    );

    const [caseId, setCaseId] = useState(null);

    // If direct_book.render_type is 'drawer', preload the DBW container
    // to avoid flash of white content while it loads since
    // it is rendered within a drawer using react-router modal route
    // instead of standard route.
    // See https://v5.reactrouter.com/web/example/modal-gallery for more info.
    const renderDirectBookInDrawer = shouldRenderDirectBookInDrawer(config);
    useEffect(() => {
      if (renderDirectBookInDrawer) {
        DBookingContainer.preload();
      }
    }, [renderDirectBookInDrawer]);

    const memoLog = useCallback(
      (eventName, eventData) => {
        let data = eventData;

        if (caseId) {
          data = { ...data, case_id: caseId };
        }

        log(eventName, data);
      },
      [caseId, log]
    );

    // Provider used for modal routing when rendering DBW within drawer in agent mode
    const provider = location.state?.provider;

    return (
      <AuthenticatedUserInfoProvider userInfo={authenticatedUserInfo}>
        <CrmConnectorProvider
          config={config}
          log={memoLog}
          machineOptions={{
            actions: {
              onPatientReceived: setCaseId
            }
          }}
        >
          <ReferralConnectorProvider config={config} log={memoLog}>
            <Suspense fallback={<LoadingOverlay loading={true} />}>
              {/* If modal routing location exists, use it. otherwise, fallback to default location. */}
              <Switch location={provider?.location || location}>
                <ProfileRoute
                  config={config}
                  log={memoLog}
                  path={'/provider/:name/:id'}
                  component={ProfileContainer}
                  searchSDK={searchSDK}
                />
                {!provider && (
                  <DirectBookRoute
                    config={config}
                    log={memoLog}
                    path="/book/:providerId/:stepName?"
                    component={DBookingContainer}
                    searchSDK={searchSDK}
                  />
                )}
                <AppointmentCancelRoute
                  config={config}
                  log={memoLog}
                  path="/cancel/:appointmentId"
                  component={AppointmentCancelContainer}
                  searchSDK={searchSDK}
                />
                <RedirectRoute
                  config={config}
                  log={memoLog}
                  path="/redirect"
                  component={RedirectContainer}
                />
                <LocationRoute
                  config={config}
                  log={memoLog}
                  path="/location/:name/:id"
                  component={LocationContainer}
                />
                <MapSearchRoute
                  config={config}
                  log={memoLog}
                  path="/locations"
                  component={
                    config.darkship_use_list_page_searchv9
                      ? MapSearchContainerV9
                      : MapSearchContainer
                  }
                />
                <SearchMatchRoute
                  config={config}
                  log={memoLog}
                  path={'/'}
                  component={
                    config.darkship_use_list_page_searchv9
                      ? HomeContainerV9
                      : HomeContainer
                  }
                />
              </Switch>

              {/* Render the DBW modal route for use within a drawer. Uses the data provided in the location state in agent mode.
                Drawer always exists in the DOM in agent mode (to allow enter/exit animations to work properly) but is not accessible without
                the location state mentioned.
              */}
              {renderDirectBookInDrawer && (
                <DirectBookDrawerRoute
                  config={config}
                  log={memoLog}
                  path="/book/:providerId/:stepName?"
                  component={DBookingContainer}
                  searchSDK={searchSDK}
                  provider={provider?.provider}
                />
              )}
              <CustomConfigActiveBanner
                authenticatedUserInfo={authenticatedUserInfo}
              />
            </Suspense>
          </ReferralConnectorProvider>
        </CrmConnectorProvider>
      </AuthenticatedUserInfoProvider>
    );
  }
);

ClientApp.propTypes = {
  // logging function connected to `@kyruus/trackyr`
  log: func.isRequired,
  // config from store state
  config: object.isRequired
};

const state = new State();
addLocaleData([...en, ...es]); // TODO we may want to do this based on configurations

/**
 * @typedef {Object} TrackingInitConfig
 * @prop {string} product_name
 * @prop {string} deployment
 * @prop {string} customer_code
 * @prop {string} user_id
 * @prop {string} tracking_token
 */

/**
 * initializes `trackyr` and returns a `log` function to be used for tracking
 * //
 * @param {{ init_metadata: TrackingInitConfig }} init_metadata
 * @returns {Function}
 **/

const initializeLogging = (logging_metadata = {}, config = {}) => {
  const { init_metadata = {}, event_metadata = {} } = logging_metadata;

  // collect external tracking params like those that google appends to tracked links
  const externalTrackingParams = getExternalTrackingParams();

  const trackyrInitMetadata = {
    ...init_metadata,
    ...externalTrackingParams,
    referrer: window.document.referrer || 'UNKNOWN'
  };

  trackyr.init(trackyrInitMetadata);

  const log = (eventName, eventData = {}) => {
    let tealiumData;

    // trackyr/muus
    const trackyrData = {
      eventName: eventName,
      eventData: {
        ...event_metadata,
        ...eventData
      }
    };

    trackyr.track(trackyrData.eventName, trackyrData.eventData);

    // google analytics
    let gaData;
    if (isGoogleAnalyticsLoaded()) {
      gaData = {
        eventName
      };
      trackGaEvent(gaData.eventName);
    } else {
      gaData = 'event skipped';
    }

    // tealium
    if (shouldTrackToTealium(eventName) && config?.tealium?.enabled) {
      tealiumData = {
        eventName,
        eventData: {
          ...externalTrackingParams,
          ...event_metadata,
          ...eventData
        }
      };
      trackTealiumEvent(tealiumData.eventName, tealiumData.eventData);
    } else {
      tealiumData = 'event skipped';
    }

    // captures an object that includes the metadata initialized in the `trackyr` object, for debugging the logs
    const muusData = {
      eventName: trackyrData.eventName,
      eventData: {
        ...trackyrInitMetadata,
        ...trackyrData.eventData
      }
    };

    if (Cookies.get('debug_analytics')) {
      // eslint-disable-next-line no-console
      console.log(
        JSON.stringify(
          {
            eventName,
            date: new Date().toISOString(),
            type: 'tracking',
            ga: gaData,
            tealium: tealiumData,
            muus: muusData
          },
          // make it look ~nice~
          null,
          '\t'
        )
      );
    }

    if (Cookies.get('debug_editor') === 'true') {
      window._admin_logging = window._admin_logging || [];
      const trackingSummaryObject = {
        eventName,
        time: Date.now(),
        ga: gaData,
        tealium: tealiumData,
        muus: muusData
      };
      window._admin_logging.push(trackingSummaryObject);
      const evt = new CustomEvent('admin_tracking_debug_call', {
        detail: trackingSummaryObject
      });
      window.dispatchEvent(evt);
    }
  };

  return { log };
};

/**
 * @param {{ init_metadata: TrackingInitConfig }} init_metadata
 */

// DEATH TO JQUERY!
function ready(fn) {
  if (window.document.readyState != 'loading') {
    fn();
  } else {
    window.document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  const initialState = state.loadStateFromPage();

  if (initialState.config?.datadog?.rum_enabled) {
    const ddRumConfig = {
      applicationId: '5841edf1-c54d-4ca4-958f-2c6d894bd1d0',
      clientToken: 'pubb895f16552dddfd9066608bded192aad',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'providermatch-consumer',
      env: initialState?.deployment?.environment,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: initialState?.deployment?.stackVersion,
      sessionSampleRate:
        initialState.config?.datadog?.rum_session_sample_rate ?? 100,
      sessionReplaySampleRate:
        initialState.config?.datadog?.rum_session_replay_sample_rate ?? 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [window.location.origin]
    };
    datadogRum.init(ddRumConfig);
  }

  const store = configureStore(initialState);

  const queryParams = querystring.parse(
    (window.location.search || '').substring(1)
  );

  // https://kyruus.jira.com/browse/KENG-31366
  // delete any bcst cookies which might have leaked pii data between users
  Cookies.remove('bcst');

  const renderApp = () => {
    const { config, customerCode, authenticatedUserInfo, settings } =
      store.getState();

    const productName = productNameSelector(store.getState());

    const {
      logging_metadata = {},
      locale = en,
      application_string_templates = {},
      theme = {}
    } = config;

    let { log } = initializeLogging(logging_metadata, config);

    // NOTE: THIS `console.log` call IS used for `functional tests`
    // hammerhead exists when testcafe runs
    // https://stackoverflow.com/a/55451665
    if (window && window['%hammerhead%']) {
      window.__setTestConfig = (override) => {
        store.dispatch(setTestConfigs(override));
      };
      log = (eventName, eventData) => {
        // eslint-disable-next-line no-console
        console.log(
          JSON.stringify({
            type: 'tracking',
            data: { eventName, eventData }
          })
        );
      };
    }

    window.addEventListener(
      'message',
      (event) => {
        // only accept events from the same origin, and window, not from other domains / iframe hosts, etc
        // Eventually if we hook this up from a different kyruus domain or PMA or somesuch, we'll want to
        // adjust this logic further
        if (
          event.origin === window.location.origin &&
          event.source === window
        ) {
          if (
            event.data?.eventName === 'pmc_editor:set_config' &&
            event.data?.eventData
          ) {
            store.dispatch(setTestConfigs(event.data.eventData));
            window.debug_rerender();
          }
        }
      },
      false
    );

    // Hydration of the ids in `data-emotion-css` will automatically occur when the cache is created
    const emotionCache = createCache();

    const customerBiomeTheme = {
      ...biomeTheme,
      ...theme
    };
    const customerTheme = {
      ...kyruusTheme,
      ...theme
    };

    ReactDOM.render(
      <ReduxProvider store={store}>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={customerTheme}>
            <BiomeThemeProvider theme={{ [THEME_ID]: customerBiomeTheme }}>
              <IntlProvider
                locale={locale}
                defaultLocale="en-US"
                messages={application_string_templates}
              >
                <BrowserRouter>
                  <ClientApp
                    log={log}
                    config={config}
                    customerCode={customerCode}
                    productName={productName}
                    authenticatedUserInfo={authenticatedUserInfo}
                  />
                </BrowserRouter>
              </IntlProvider>
            </BiomeThemeProvider>
          </ThemeProvider>
        </CacheProvider>
      </ReduxProvider>,
      window.document.getElementById(APP_ID)
    );

    const fullstoryEnabled = getConfigProperty(
      config,
      'third_party.fullstory.enabled'
    );

    // init full story when enabled
    if (fullstoryEnabled === true && settings.FULLSTORY_ORG_ID != null) {
      FullStory.init({
        orgId: settings.FULLSTORY_ORG_ID,
        // add support for iframed environments
        recordOnlyThisIFrame: true
      });
    }
  };

  const renderEditorApp = () => {
    const isEditorEnabled =
      window.document.cookie.indexOf('debug_editor=true') > -1;

    if (!isEditorEnabled) {
      // if editor cookie is disabled, don't render it
      return;
    }
    let domElement = window.document.getElementById(EDITOR_APP_ID);
    if (domElement) {
      // if element already exists, exit here as we've already rendered it
      return;
    }

    // create the dom element for us to render the react tree into
    domElement = window.document.createElement('div');
    domElement.id = EDITOR_APP_ID;
    window.document.body.appendChild(domElement);

    ReactDOM.render(
      <CacheProvider value={createCache()}>
        <ThemeProvider theme={kyruusTheme}>
          <BiomeThemeProvider theme={{ [THEME_ID]: biomeTheme }}>
            <Suspense fallback={<div>Loading...</div>}>
              <PmcEditor />
            </Suspense>
          </BiomeThemeProvider>
        </ThemeProvider>
      </CacheProvider>,
      domElement
    );
  };

  loadableReady(() => {
    renderApp();
    renderEditorApp();
    // send event that we can hook into for testing
    window.pmc_ready = true;
    const readyEvt = new Event('pmc_ready');
    window.dispatchEvent(readyEvt);
  });

  // re-render the entire app client-side. for debug use only
  window.debug_rerender = () => {
    ReactDOM.unmountComponentAtNode(window.document.getElementById(APP_ID));
    renderApp();
  };

  // simple utils for speeding up dev by allowing bookmarklets to
  // access these features more easily and future-proof-ly
  window.admin = {
    enableEditor: () => {
      // if (!/\.kyruus\.com$/.test(window.location.hostname)) {
      //   alert('This feature is only available on *.kyruus.com domains');
      //   return;
      // }
      window.document.cookie = 'debug_editor=true;path=/';
      renderEditorApp();
    },
    disableEditor: () => {
      window.document.cookie =
        'debug_editor=false;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
      window.admin.disableStringDebug();
      const domElem = window.document.getElementById(EDITOR_APP_ID);
      ReactDOM.unmountComponentAtNode(domElem);
      domElem.remove();
    },
    enableStringDebug: () => {
      window.document.cookie = 'string_debug=true;path=/';
      window.debug_rerender();
    },
    disableStringDebug: () => {
      window.document.cookie =
        'string_debug=false;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
      window.debug_rerender();
    }
  };

  if (CERNER_BCS_TOKEN_QUERY_PARAM in queryParams) {
    store.dispatch(setBcsToken(queryParams[CERNER_BCS_TOKEN_QUERY_PARAM]));
  }
});
