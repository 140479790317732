import { styled as biomeStyled } from '@biome-design-system/styles';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Banner } from '@kyruus/banner';
import Button, { ButtonLink } from '@kyruus/button';
import Chip from '@kyruus/chip';
import { fromTheme } from '@kyruus/ui-theme';
import { Link } from 'react-router-dom';
import PoweredByKyruusLogo from '../shared/powered-by-logo';
import zIndex from '../styles/z-index-constants';
import SearchPagination from './pagination';

export const StyledCrmWarning = styled('div')`
  margin-top: ${fromTheme('spacing_medium')};
  margin-bottom: ${fromTheme('spacing_medium')};
`;

export const StyledChipsContainer = styled.div`
  display: flex;
  gap: ${fromTheme('spacing_medium')} ${fromTheme('spacing_small')};
  flex-wrap: wrap;
  margin-bottom: ${fromTheme('spacing_medium')};
  line-height: ${fromTheme('font_line_height')};

  &:focus-within {
    background-color: ${fromTheme('color_background_secondary')};
    border-radius: ${fromTheme('border_radius')};
    padding: ${fromTheme('spacing_small')} 0;
    margin: -${fromTheme('spacing_small')} 0 ${fromTheme('spacing_small')} 0;
  }
`;

export const AvailabilityControlsContainer = styled.div`
  margin-bottom: ${fromTheme('spacing_medium')};
`;

export const LoadingSearchWidget = styled.div`
  opacity: 0.1;
  min-height: 212px;
  @media (min-width: ${fromTheme('screen_medium')}) {
    min-height: 136px;
  }
`;

export const MobileViewResultWrapper = styled.div`
  position: absolute;
  z-index: 1;
`;

export const MobileViewResultContainer = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 40px;
  width: 160px;
  margin: 0 auto;
  display: block;

  @media only screen and (min-width: ${fromTheme('screen_medium')}) {
    display: none;
  }
`;

export const MobileViewResultBtn = styled.button`
  background-color: ${fromTheme('color_button')};
  color: ${fromTheme('color_text_contrast')};
`;

export const StyledButtonTopPanel = styled('div')`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const StyledFacetsFixedTop = styled('div')`
  border-bottom: 1px solid ${fromTheme('color_border')};
  height: ${fromTheme('spacing_large')};
  width: 100%;
  padding: ${fromTheme('spacing_medium')} ${fromTheme('spacing_medium')}
    ${fromTheme('spacing_large')};
  overflow: hidden;
  background: ${fromTheme('color_background')};
  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    width: 100%;
  }
`;

export const StyledCloseButton = styled(Button)`
  padding: 0;
  color: #087db9;
  float: right;
  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
`;

export const StartNewSearchLink = styled(Link)`
  margin-bottom: ${fromTheme('spacing_medium')};
`;

const facetPanelMobileStyle = (props) => css`
  /* iOS Safari: This enables momentum scrolling KENG-3993 */
  -webkit-overflow-scrolling: ${props.showMobileFacets ? `touch` : `auto`};
  display: ${props.showMobileFacets ? `block` : `none`};
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  background: white;
  z-index: ${zIndex.dropdown};
  padding: 50px 0 70px;

  .filter-group {
    max-width: 100%;
  }

  select {
    padding: 5px;
  }
`;

export const StyledFacetPanel = styled('div')`
  margin-bottom: 150px;
  ${(props) => props.showMobileFacets && facetPanelMobileStyle};
`;

export const StyledFacetListHeader = styled('h1')`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export const StyledFacetListHeaderWithMobileFacets = styled('h1')`
  font-size: ${fromTheme('font_size_heading_4')};
  margin: ${fromTheme('spacing_medium')} 0 ${fromTheme('spacing_small')};
`;

export const FacetFieldSet = styled.fieldset`
  &.filter-group {
    padding: ${fromTheme('spacing_medium')} 0 ${fromTheme('spacing_medium')};
  }
`;

export const StyledLegend = styled('legend')`
  font-size: ${fromTheme('font_size_heading_5')};
  font-weight: ${fromTheme('font_weight_bold')};
  float: left;
  line-height: ${fromTheme('font_line_height')};
  padding: 0 ${fromTheme('spacing_medium')};
`;

export const FacetWrapper = styled('div')`
  display: grid;
  grid-template-areas: 'facetbody facetbody' 'showmore clearfilter';
  grid-auto-rows: minmax(min-content, 0);
  grid-template-columns: repeat(2, 1fr);
  row-gap: ${fromTheme('spacing_small')};
  column-gap: ${fromTheme('spacing_medium')};
  padding-top: ${fromTheme('spacing_small')};
  clear: both;
`;

export const SearchContainer = styled.div`
  ${(props) =>
    props.v9 &&
    `
      max-width: 1440px;
      margin-right: auto;
      margin-left: auto;
      padding-left: ${fromTheme('spacing_large')(props)};
      padding-right: ${fromTheme('spacing_large')(props)};
    `}
`;

export const TypeaheadSelectBody = styled.div`
  grid-area: facetbody;
  padding: 0 ${fromTheme('spacing_medium')};
  margin: ${fromTheme('spacing_small')} 0 ${fromTheme('spacing_small')};

  .Select-control {
    border-radius: ${fromTheme('border_radius')};
  }
`;

export const BasicFacetBody = styled.ul`
  grid-area: facetbody;
`;

export const ShowMoreButtonLink = styled(ButtonLink)`
  grid-area: showmore;
  border: none;
  color: ${fromTheme('color_link')};
  margin: 0 auto 0 ${fromTheme('spacing_medium')};
  padding: 0;

  &:hover,
  &:focus {
    background: none;
    border: none;
    text-decoration: underline;
  }
`;

export const ClearFilterLink = styled(Link)`
  grid-area: clearfilter;
  color: ${fromTheme('color_link')};
  font-size: ${fromTheme('font_size')};
  margin: 0 ${fromTheme('spacing_medium')} 0 auto;

  &:hover {
    background: none;
    text-decoration: underline;
  }
`;

export const SearchHomeFilterBanner = styled(Banner)`
  margin-top: ${fromTheme('spacing_medium')};
`;

export const Footer = styled.div`
  // KENG-28970
  // Adding some extra margin to the footer when crm is enabled
  // and referral list drawer is open
  margin-bottom: ${(props) => () =>
    props.hasReferrals ? `300px;` : fromTheme('spacing_xxlarge')};

  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${fromTheme('spacing_xlarge')};
  grid-template-areas: ${({ showPagination, showDisclaimer }) => {
    let template = '';
    if (showPagination) {
      template += `'pagination'`;
    }
    if (showDisclaimer) {
      template += `'disclaimer'`;
    }
    template += `'poweredby'`;
    return template;
  }};

  button {
    background: transparent;
  }

  @media only screen and (min-width: ${fromTheme('screen_medium')}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ${({ showPagination, showDisclaimer }) => {
      let template = '';
      if (showPagination) {
        if (showDisclaimer) {
          template = `'pagination poweredby' 'disclaimer disclaimer'`;
        } else {
          template = `'pagination poweredby'`;
        }
      } else {
        if (showDisclaimer) {
          template = `'disclaimer poweredby'`;
        } else {
          template = `'poweredby poweredby'`;
        }
      }
      return template;
    }};
  }
`;

export const Pagination = styled(SearchPagination)`
  grid-area: pagination;
  margin-bottom: ${fromTheme('spacing_xlarge')};

  @media print {
    visibility: hidden;
  }
`;

export const PoweredByLogo = styled(PoweredByKyruusLogo)`
  grid-area: poweredby;
`;

export const Disclaimer = styled.div`
  grid-area: disclaimer;
  font-size: ${fromTheme('font_size_small')};
  color: ${fromTheme('color_text_secondary')};
  text-align: center;
  align-self: center;

  @media only screen and (min-width: ${fromTheme('screen_medium')}) {
    text-align: left;
  }
`;

export const FacetChip = styled(Chip)`
  && {
    background-color: ${fromTheme('color_button')};
    color: ${fromTheme('color_text_contrast')};
    font-family: ${fromTheme('font_face')};
    font-size: ${fromTheme('font_size')};
    font-weight: ${fromTheme('font_weight')};
    &:focus,
    &:focus-within {
      background-color: ${fromTheme('color_button')};
      outline-color: ${fromTheme('color_button')};
      outline-width: 2px;
      outline-style: ${fromTheme('border_style')};
      outline-offset: 2px;
    }
  }
`;

//Biome Chip leads to error saying 'reading mode of undefined' if we set feature flag for updated_filter_bar to true
export const FacetBiomeChip = biomeStyled(Chip)`
  && {
    font-family: ${fromTheme('font_face')};
    font-size: ${fromTheme('font_size')};
    font-weight: ${fromTheme('font_weight')};
  }
`;

export const FacetAllFiltersBiomeChip = biomeStyled(FacetBiomeChip)`
  && {
    background-color: transparent;
    color: ${fromTheme('color_button')};
  }
`;

export const RemoveFilterChipButton = styled(Button)`
  && {
    background: none;
    border: none;
    padding: 0;
    width: 25px;
    height: 25px;

    &:focus,
    &:focus-visible,
    &:active,
    &:active:not(:disabled) {
      background: none;
      outline: none;
    }
  }
`;

export const HorizontalFilterBar = biomeStyled('div')`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${fromTheme('spacing_medium')};
    gap: ${fromTheme('spacing_medium')};
  }
`;

export const HorizontalFilters = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px 8px;
  flex-wrap: wrap;
`;

export const ExpandedFilterDrawerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;
